import { Component, Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AsyncSubject, BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map, take, concatMap, first, mergeMap, skipWhile } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

interface User {
  id: string;
  email: string;
  role: string;
  roleAuth: any;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userDataSubject = new BehaviorSubject<any>(undefined);
  userData = this.userDataSubject.asObservable();

  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth, private router: Router, private ngZone: NgZone, private message: MessageService) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.afs
          .doc<User>(`users/${user.uid}`)
          .valueChanges()
          .subscribe((data) => {
            if (data) {
              if (data.role == 'admin' || data.role == 'panel' || data.role == 'editor') {
                this.userDataSubject.next(data);
              } else {
                return this.afAuth.signOut();
              }
            } else {
              return this.afAuth.signOut();
            }
          });
      } else {
        this.userDataSubject.next(undefined);
      }
    });
  }

  SignIn(email: string, password: string) {
    this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        this.ngZone.run(() => {
          this.afs
            .doc<User>(`users/${res.user.uid}`)
            .valueChanges()
            .subscribe((data) => {
              if (data) {
                if (data.role == 'admin' || data.role == 'panel' || data.role == 'editor') {
                  this.userDataSubject.next(data);
                  this.router.navigate(['dashboard']);
                } else {
                  this.message.add({ severity: 'error', summary: 'Uyarı', detail: 'Hatalı giriş yaptınız.' });
                  return this.afAuth.signOut();
                }
              } else {
                this.message.add({ severity: 'error', summary: 'Uyarı', detail: 'Hatalı giriş yaptınız.' });
                return this.afAuth.signOut();
              }
            });
        });
      })
      .catch((err) => {
        this.message.add({ severity: 'error', summary: 'Uyarı', detail: 'Hatalı giriş yaptınız.' });
      });
  }

  getUser(): Observable<any> {
    return this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc<any>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  checkAuthor(path): Observable<any> {
    return this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs
            .doc<any>(`users/${user.uid}`)
            .valueChanges()
            .pipe(
              switchMap((docs) => {
                if (docs) {
                  if (docs.role == 'admin' || docs.role == 'panel') {
                    return this.afs
                      .collection<any[]>(path[1], (ref) => ref.where('id', '==', path[2]))
                      .valueChanges()
                      .pipe(
                        map((x) => {
                          if (x.length != 0) {
                            return true;
                          } else {
                            return 1;
                          }
                        })
                      );
                  } else if (docs.role == 'editor') {
                    return this.afs
                      .collection<any[]>(path[1], (ref) => ref.where('id', '==', path[2]).where('meta.author.id', '==', user.uid))
                      .valueChanges()
                      .pipe(
                        map((x) => {
                          if (x.length != 0) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                      );
                  } else {
                    return of(null);
                  }
                }
              })
            );
        } else {
          return of(null);
        }
      })
    );
  }

  SignOut() {
    return this.afAuth.signOut().then(() => {
      this.userDataSubject.next(undefined);
      this.router.navigate(['login']);
    });
  }
}
