<div class="login p-0 m-0 h-100 d-flex justify-content-center align-items-center">
    <div class="login-content p-5">
        <form autocomplete="one-time-code" [formGroup]="myForm" (ngSubmit)="login()">
            <div class="logo d-flex justify-content-center mb-4 disabled-copy">
                <img src="assets/images/logo.png" alt="GeziBilen">
                <h4 class="d-flex align-items-center">GeziBilen<br>
                    Yönetim Paneli
                </h4>
            </div>
            <input name="Fake_Username" id="Fake_Username" type="text" style="display:none">
            <input name="Fake_Password" id="Fake_Password" type="password" style="display:none">
            <div class="input-group mb-4">
                <i class="pi pi-user"></i>
                <input type="text"
                    [class.invalid]="myForm.controls.email.status == 'INVALID' && myForm.controls.email.value!='' && (myForm.controls.email.dirty || myForm.controls.email.touched)"
                    autocomplete="one-time-code" placeholder="Personel Maili" formControlName="email">
            </div>
            <div class="input-group mb-4">
                <i class="pi pi-key"></i>
                <input type="password"
                    [class.invalid]="myForm.controls.password.status == 'INVALID' && myForm.controls.password.value!='' && (myForm.controls.password.dirty || myForm.controls.password.touched)"
                    autocomplete="one-time-code" placeholder="Şifre" formControlName="password">
            </div>
            <div class="input-group mb-4">
                <input type="submit" value="Giriş Yap">
            </div>
            <a href="#" class="text-center password">Sisteme giriş bilgilerini mi unuttun?</a>
        </form>
    </div>
</div>