<div class="main w-100 h-100">
  <div class="menu">
    <div class="header">
      <div class="logo d-flex align-items-center disabled-copy mb-3" [routerLink]="['/dashboard']">
        <img src="assets/images/logo.png" alt="" />
        <div class="h4">GeziBilen</div>
      </div>
      <div class="gezi-custom-select disabled-copy">
        <p-dropdown [options]="cityItems" optionLabel="name" optionValue="name" [formControl]="citySelect" (onChange)="cityChange($event)"> </p-dropdown>
      </div>
    </div>
    <ul id="main-menu" class="menu-items">
      <li *ngFor="let item of authItems" [ngClass]="{ active: (item.path == activePath[1] && citySelect.value == activePath[3]) || (item.path == activePath[1] && citySelect.value == activePath[2]) }">
        <a *ngIf="item.page == 'list'" [routerLink]="['/' + item.path, item.page, citySelect.value]"
          ><span class="menu-item-icon"><i class="bi {{ item.icon }}"></i></span>{{ item.title }}</a
        >
        <a *ngIf="item.page == 'edit'" [routerLink]="['/' + item.path, citySelect.value]"
          ><span class="menu-item-icon"><i class="bi {{ item.icon }}"></i></span>{{ item.title }}</a
        >
      </li>
      <!-- <li><a [routerLink]="[ '/' + 'blogs','add','Adıyaman']"><span class="menu-item-icon"><i
                            class="ms-Icon ms-Icon--POI"></i></span>ankara</a>
            </li>
            <li><a [routerLink]="[ '/' + 'blogs','7b6yeoCCX8Wr9ZS485mr']"><span class="menu-item-icon"><i
                            class="ms-Icon ms-Icon--POI"></i></span>yok</a>
            </li>
            <li><a [routerLink]="[ '/' + 'blogs','5pfQysrmax7KFvKpXsz1']"><span class="menu-item-icon"><i
                            class="ms-Icon ms-Icon--POI"></i></span>var</a>
            </li> -->
      <li class="submenu" *ngIf="publicAuth.length != 0">
        <a
          ><span class="menu-item-icon"><i class="bi bi-sliders"></i></span>Genel Özellikler</a
        >
        <ul>
          <li *ngFor="let item of publicItems">
            <a [routerLink]="['/' + item.path]"
              ><i class="bi {{ item.icon }}"></i><span>{{ item.title }}</span></a
            >
          </li>
        </ul>
      </li>
    </ul>
    <div class="footer">
      <span class="avatar disabled-copy">
        <img *ngIf="userData?.avatar" src="{{ userData?.avatar }}" alt="" />
      </span>
      <span class="data disabled-copy">
        <span class="name">{{ userData?.name }}</span>
        <span class="role">{{ userData?.role == "editor" ? "Editör" : "Admin" }}</span>
      </span>
      <a class="quit" (click)="logout()"><i class="bi bi-arrow-bar-right"></i></a>
    </div>
  </div>
  <div class="content p-5">
    <div class="center ml-auto mr-auto" style="max-width: 1000px">
      <router-outlet></router-outlet>
    </div>
  </div>

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" name="spinner" size="medium" color="#fff" type="ball-spin-clockwise" [fullScreen]="true">
    <p style="color: white">Yükleniyor...</p>
  </ngx-spinner>
</div>
