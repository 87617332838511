// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: { //gezBi
    apiKey: "AIzaSyB8NSVjqWZufBD_DnVc8cTU2HPfnW4HnVc",
    authDomain: "gezbi-e89bc.firebaseapp.com",
    projectId: "gezbi-e89bc",
    storageBucket: "gezbi-e89bc.appspot.com",
    messagingSenderId: "424407991960",
    appId: "1:424407991960:web:d73949afc1c69095a132ed",
    measurementId: "G-M10MTVZ1VY"
  }
  // firebase: { // gezBiTest
  //   apiKey: "AIzaSyD2PnJMy6hBGvZDqTb5ankURiz57lKw-Hk",
  //   authDomain: "gezbitest.firebaseapp.com",
  //   projectId: "gezbitest",
  //   storageBucket: "gezbitest.appspot.com",
  //   messagingSenderId: "512601865105",
  //   appId: "1:512601865105:web:d5e8ff94f27486034fb830",
  //   measurementId: "G-HE6TN47E4T"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
