import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';

import { SidebarComponent } from './sidebar/sidebar.component';

import { AuthGuard } from './services/auth.guard';

import { AuthorGuard } from './services/author.guard';

export const AppRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: SidebarComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'blogs',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/blog/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'trash',
            loadChildren: () => import('./pages/blog/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            children: [
              {
                path: ':city',
                loadChildren: () => import('./pages/blog/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/blog/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthorGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'travelList',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/travelList/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'trash',
            loadChildren: () => import('./pages/travelList/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'short-add',
            children: [
              {
                path: ':city',
                children: [
                  {
                    path: '',
                    loadChildren: () => import('./pages/travelList/short-add/short-add.module').then((m) => m.ShortAddModule),
                    canActivate: [AuthorGuard],
                  },
                  {
                    path: ':id',
                    loadChildren: () => import('./pages/travelList/short-add/short-add.module').then((m) => m.ShortAddModule),
                    canActivate: [AuthorGuard],
                  },
                ],
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: 'add',
            children: [
              {
                path: ':city',
                loadChildren: () => import('./pages/travelList/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: ':city',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/travelList/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthorGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'cities',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/cities/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'trash',
            loadChildren: () => import('./pages/cities/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            loadChildren: () => import('./pages/cities/edit/edit.module').then((m) => m.EditModule),
            canActivate: [AuthGuard],
          },
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/cities/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'news',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/news/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'trash',
            loadChildren: () => import('./pages/news/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            loadChildren: () => import('./pages/news/edit/edit.module').then((m) => m.EditModule),
            canActivate: [AuthGuard],
          },
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/news/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthorGuard],
              },
            ],
          },
        ],
      },
      // {
      //   path: 'users',
      //   children: [
      //     {
      //       path: 'list',
      //       loadChildren: () => import('./pages/user/list/list.module').then((m) => m.ListModule),
      //       canActivate: [AuthGuard],
      //     },
      //     {
      //       path: '',
      //       children: [
      //         {
      //           path: ':id',
      //           loadChildren: () => import('./pages/user/edit/edit.module').then((m) => m.EditModule),
      //           canActivate: [AuthorGuard],
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        path: 'events',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/event/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'trash',
            loadChildren: () => import('./pages/event/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            children: [
              {
                path: ':city',
                loadChildren: () => import('./pages/event/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/event/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthorGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'bilenBilir',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/bilenbilir/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'trash',
            loadChildren: () => import('./pages/bilenbilir/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            children: [
              {
                path: ':city',
                loadChildren: () => import('./pages/bilenbilir/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/bilenbilir/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthorGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'routes',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/route/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'trash',
            loadChildren: () => import('./pages/route/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'home',
            loadChildren: () => import('./pages/route/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            children: [
              {
                path: ':city',
                loadChildren: () => import('./pages/route/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/route/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthorGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'travelPoints',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/travelpoint/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'trash',
            loadChildren: () => import('./pages/travelpoint/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'home',
            loadChildren: () => import('./pages/travelpoint/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            children: [
              {
                path: ':city',
                loadChildren: () => import('./pages/travelpoint/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/travelpoint/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthorGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'popularPhotos',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/popularphoto/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'trash',
            loadChildren: () => import('./pages/popularphoto/list/list.module').then((m) => m.ListModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            children: [
              {
                path: ':city',
                loadChildren: () => import('./pages/popularphoto/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/popularphoto/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthorGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'cityGuides',
        children: [
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/cityguide/cityguide.module').then((m) => m.CityGuideModule),
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'cityBackgrounds',
        children: [
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/citybackground/citybackground.module').then((m) => m.CityBacgkroundModule),
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'comments',
        loadChildren: () => import('./pages/comments/comments.module').then((m) => m.CommentsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'blocked',
        loadChildren: () => import('./pages/blockedlist/blockedlist.module').then((m) => m.CommentsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'event-categories',
        loadChildren: () => import('./pages/event-categories/event-categories.module').then((m) => m.EventCategoriesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'suggest',
        loadChildren: () => import('./pages/suggest/suggest.module').then((m) => m.SuggestModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'stories',
        loadChildren: () => import('./pages/story/list/list.module').then((m) => m.ListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'stories',
        children: [
          {
            path: 'add',
            loadChildren: () => import('./pages/story/edit/edit.module').then((m) => m.EditModule),
            canActivate: [AuthGuard],
          },
          {
            path: '',
            children: [
              {
                path: ':id',
                loadChildren: () => import('./pages/story/edit/edit.module').then((m) => m.EditModule),
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '/login' },
];
