import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from "@angular/router";
import { MessageService } from 'primeng/api';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: []
})
export class LoginComponent implements OnInit {

  myForm: FormGroup;

  constructor(public router: Router, private message: MessageService, private auth: AuthService,
    private fb: FormBuilder) { }

  ngOnInit(): void {

    this.auth.userData.subscribe(success => {
      if (success) {
        if (success != undefined) {
          this.router.navigate(['dashboard']);
        }
      }
    })

    this.myForm = this.fb.group({
      email: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]),
      password: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.minLength(6)]),
    });
  }

  login() {
    if (this.myForm.status == "VALID") {
      this.auth.SignIn(this.myForm.value.email, this.myForm.value.password);
    } else {
      if (this.myForm.value.email == "" && this.myForm.value.password == "") {
        this.message.add({ severity: 'error', summary: 'Uyarı', detail: 'Tüm alanları doldunuz.' });
      } else {
        this.message.add({ severity: 'error', summary: 'Uyarı', detail: 'Hatalı giriş yaptınız.' });
      }

    }
  }


}
