import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
declare function menuFunction(): any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  constructor(private auth: AuthService, private http: HttpClient, private router: Router, private afs: AngularFirestore) {}

  activePath: any;
  citySelect = new FormControl();

  userData: any;
  cityList: any = [];
  cityItems: any = [];
  authItems: any = [];
  publicItems: any = [];

  cityAuth: any = [
    { title: 'Rotalar', icon: 'bi-map', page: 'list', path: 'routes' },
    { title: 'Gezi Noktaları', icon: 'bi-geo-alt', page: 'list', path: 'travelPoints' },
    { title: 'Özel Gezi Listeleri', icon: 'bi-list-ul', page: 'list', path: 'travelList' },
    { title: 'Bloglar', icon: 'bi-pencil-square', page: 'list', path: 'blogs' },
    { title: 'Bilen Bilirler', page: 'list', icon: 'bi-lightning-charge', path: 'bilenBilir' },
    { title: 'Şehir Arkaplanları', icon: 'bi-camera', page: 'edit', path: 'cityBackgrounds' },
    { title: 'Şehir Rehberleri', icon: 'bi-building', page: 'edit', path: 'cityGuides' },
    { title: 'Etkinlikler', icon: 'bi-stars', page: 'list', path: 'events' },
    { title: 'Popüler Fotoğraflar', icon: 'bi-images', page: 'list', path: 'popularPhotos' },
  ];
  publicAuth: any = [
    { title: 'Şehirler', icon: 'bi-building', path: 'cities/list' },
    { title: 'Haberler', icon: 'Chat', path: 'news/list' },
    { title: 'İletişim', icon: 'Chat', path: 'contact/waiting' },
    { title: 'Yorumlar', icon: 'Chat', path: 'comments/waiting' },
    { title: 'Önerilen Gezi Noktaları', icon: 'Shield', path: 'suggest/travelPoints/waiting' },
    { title: 'Önerilen Rotalar', icon: 'Shield', path: 'suggest/routes/waiting' },
    // { title: "Rütbeler", icon: "Medal", path: "ranks" },
    // { title: "Rozetler", icon: "Shield", path: "badges" },
    { title: 'Storyler', icon: 'Photo2', path: 'stories' },
    // { title: 'Üyeler', icon: 'People', path: 'users/list' },
    { title: 'Engellenenler', icon: 'People', path: 'blocked/keyword' },
    { title: 'Etkinlik Kategorileri', icon: 'People', path: 'event-categories' },
  ];

  ngOnInit(): void {
    this.afs
      .collection('cities')
      .valueChanges()
      .subscribe((data) => {
        // this.http.get('/assets/city.json').subscribe((data) => {
        this.cityList = data;
        // order the fields
        this.cityList.sort((a, b) => {
          return a.order - b.order;
        });
        this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
          const path = event.url.split('/');

          path[3] = decodeURIComponent(path[3]);

          this.activePath = path;
        });

        const self = this;
        this.auth.userData.subscribe((user) => {
          if (user != undefined) {
            menuFunction();

            this.userData = user;
            this.authItems = [];
            this.cityItems = [];
            this.publicItems = [];
            if (user.role == 'editor') {
              let requestsAuth = this.cityAuth.reduce((promiseChain, item) => {
                return promiseChain.then(
                  () =>
                    new Promise((resolve) => {
                      asyncAuth(item, resolve);
                    })
                );
              }, Promise.resolve());

              function asyncAuth(auth, cb) {
                if (user.roleAuth[auth.path]) {
                  let requestsCity = user.roleAuth[auth.path].reduce((promiseChain, cityName) => {
                    return promiseChain.then(
                      () =>
                        new Promise((resolvee) => {
                          asyncCity(auth, cityName, resolvee);
                        })
                    );
                  }, Promise.resolve());
                }

                cb();
              }

              function asyncCity(auth, cityName, cb2) {
                const find = self.cityItems.findIndex((a) => a.name === cityName);
                if (find == -1) {
                  self.cityItems.push({ name: cityName, auth: [auth] });
                } else {
                  self.cityItems[find].auth = self.cityItems[find].auth.concat(auth);
                }
                cb2();
              }

              requestsAuth.then(() => {
                if (this.cityItems[0]) {
                  this.citySelect.setValue(this.cityItems[0].name);
                  this.cityItems[0].auth.forEach((element) => {
                    this.authItems.push(element);
                  });
                }
                const path = this.router.url.split('/');
                path[3] = decodeURIComponent(path[3]);
                this.activePath = path;
                if (path[3].toString() !== 'undefined' && path[3].toString() !== 'waiting' && path[3].toString() !== 'active' && path[3].toString() !== 'deactive') {
                  this.citySelect.setValue(path[3]);
                }
              });

              this.publicAuth.forEach((auth) => {
                if (user.roleAuth[auth.path] == true) {
                  this.publicItems.push(auth);
                }
              });
            } else {
              //FIXME: şehirden atma hatası muhtemelen burada

              this.cityList.forEach((city) => {
                this.cityItems.push({ name: city.name, auth: this.cityAuth });
              });

              this.publicItems = this.publicAuth;
              this.citySelect.setValue(this.cityItems[0].name);
              this.cityItems[0].auth.forEach((element) => {
                this.authItems.push(element);
              });
              const path = this.router.url.split('/');
              path[3] = decodeURIComponent(path[3]);
              this.activePath = path;
              if (path[3].toString() !== 'undefined' && path[3].toString() !== 'waiting' && path[3].toString() !== 'active' && path[3].toString() !== 'deactive') {
                this.citySelect.setValue(path[3]);
              }
            }
          }
        });
      });
  }

  cityChange(item) {
    this.authItems = [];
    const self = this;
    setTimeout(function () {
      self.cityItems
        .find((a) => a.name === item.value)
        .auth.forEach((element) => {
          self.authItems.push(element);
        });
    }, 100);
  }

  logout() {
    this.authItems = [];
    this.cityItems = [];
    this.auth.SignOut();
  }
}
