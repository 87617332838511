import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { tap, map, take, switchMap, flatMap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = state.url.split('/');
    return this.authService.getUser().pipe(
      switchMap((user) =>
        of(user).pipe(
          map((user) => {
            if (user) {
              if (user.role == 'editor') {
                if ((path[1] == 'blogs' || path[1] == 'bilenBilir' || path[1] == 'travelPoints' || path[1] == 'routes' || path[1] == 'popularPhotos' || path[1] == 'events', path[1] == 'travelList', path[1] == 'example-editor')) {
                  if (path[2]) {
                    if (path[2] == 'add' || path[2] == 'list') {
                      const some = user.roleAuth[path[1]].some((item) => {
                        return item == decodeURIComponent(path[3]);
                      });
                      if (some == true) {
                        return true;
                      } else {
                        this.router.navigate(['/login']);
                        return false;
                      }
                    }
                  }
                } else if (path[1] == 'cityGuides' || path[1] == 'cityBackgrounds') {
                  if (path[2]) {
                    const some = user.roleAuth[path[1]].some((item) => {
                      return item == decodeURIComponent(path[2]);
                    });
                    if (some == true) {
                      return true;
                    } else {
                      this.router.navigate(['/login']);
                      return false;
                    }
                  }
                } else {
                  return true;
                }
              } else if (user.role == 'admin' || user.role == 'panel') {
                return true;
              } else {
                this.router.navigate(['/login']);
                this.authService.SignOut();
                return false;
              }
            } else {
              this.router.navigate(['/login']);
              return false;
            }
          })
        )
      )
    );
  }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   const path = state.url.split("/");
  //   return this.authService.getUser().pipe(
  //     map(user => {
  //       if (user) {
  //         if (user.role == "editor") {
  //           console.log(user);

  //           if (path[1] == "blogs") {
  //             console.log("t");

  //             if (path[2]) {
  //               if ((path[2] == "add") || (path[2] == "list")) {
  //                 console.log(path);
  //                 console.log(user.roleAuth.blogs);

  //                 return user.roleAuth.blogs.some((item) => {
  //                   return item == decodeURIComponent(path[3]);
  //                 })
  //               } else {
  //                 console.log("s");
  //                 return this.authService.checkAuthor(path).subscribe(x => {
  //                   return x

  //                 })
  //               }
  //             }
  //           } else {
  //             // değişecek
  //             return true;
  //             // return this.authService.checkAuthor(path).pipe(map(x => {
  //             //   console.log(x);

  //             //   return x
  //             // }))
  //           }
  //         } else if (user.role == "admin") {
  //           return true;
  //         } else {
  //           this.router.navigate(['/login']);
  //           this.authService.SignOut();
  //           return false;
  //         }
  //       } else {
  //         this.router.navigate(['/login']);
  //         return false;
  //       }

  //     })
  //   );
  // }
}
